import React from 'react';
import { Example } from './example';

function App() {
  return (
    <Example />
  );
}

export default App;
